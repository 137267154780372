import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

export const ContactRedirect = ({ staticContext }) => {
    const { anything, anything2 } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (anything2) {
            if (staticContext) {
                staticContext.status = 302;
                staticContext.url = `/contact`;
            } else {
                navigate(`/book-best-makeup-artist-faridabad`, { replace: true });
            }
        } else if (anything) {
            if (staticContext) {
                staticContext.status = 302;
                staticContext.url = `/book-${anything}`;
            } else {
                navigate(`/book-${anything}`, { replace: true });
            }
        }
    }, [anything, anything2, navigate, staticContext]);

    return null;
};